import { FormattedMessage } from 'react-intl';
import { useContext } from 'react';
import TimerView from './TimerView';
import { Timer } from '../vehicleChargingConfigurationTypes';
import { VehicleDetailsContext } from '../../../../../../../../providers/contexts';
import CreateTimerButtons from './CreateTimerButtons';

const TimerListView = () => {
    const { unsavedTimers, isReadOnly, unsavedHasControl } = useContext(VehicleDetailsContext);

    return (
        <>
            <div className="text-medium">
                <FormattedMessage id="e4c.vehicle.details.timerCharging.addTimer.title" />
            </div>
            <div className="text-color-dark">
                <FormattedMessage id="e4c.vehicle.details.timerCharging.addTimer.description" />
            </div>
            {!isReadOnly && unsavedHasControl && <CreateTimerButtons />}
            {unsavedTimers?.map((timer: Timer, index) => <TimerView key={index} timer={timer} />)}
        </>
    );
};

export default TimerListView;
