import { Moment } from 'moment';
import { OverviewPropertiesFromDispatch, OverviewPropertiesFromState } from './containers/types';
import ElectricVehicle from '../vehicleCharging/models/ElectricVehicle';

export type OverviewProperties = OverviewPropertiesFromState & OverviewPropertiesFromDispatch;

export interface OverviewTeaserProperties {
    sVehicleCount: number;
    mVehicleCount: number;
    vehiclesCount: number;
    fetchingElectricVehicles: boolean;
}

export interface VehicleStatusPanelProps {
    vehiclesDrivingCount: number;
    vehiclesReadyToDriveCount: number;
    fetchingVehicles: boolean;
    hasMProductBooking: boolean;
}

export interface ChargingStatusPanelProps {
    vehicles: ElectricVehicle[];
    fetchingVehicles: boolean;
}

export interface VehiclesSelectionDropDownPros {
    currentEndDate: string;
    currentStartDate: string;
    setShowSelectionDropdown: (status: boolean) => void;
    selectedVehicleIds: string[] | MultiselectOptionsType[];
    vehiclesCount: number;
}

export interface KpiSelectionDropDownPros {
    selectedKpi: KpiEnum;
    handleSelectedKpi: (kpi: KpiEnum) => void;
}

export interface BarChartPanelProps {
    defaultEndValue: Moment | (() => Moment);
    defaultStartValue: Moment | (() => Moment);
    fetchingVehiclesStatistics: boolean;
    initiallySelectedKpi: KpiEnum;
    localStoragePrefix: string;
    vehicles: ElectricVehicle[];
    vehiclesCount: number;
    vehiclesStatistics: VehicleStatistics[];
}

export interface SelectionDropdownProps {
    currentEndDate: Moment;
    currentStartDate: Moment;
    changeDatesFromDropDown: (startValue: Moment, endValue: Moment) => void;
    handleChange: (selectedItemIds: string[]) => void;
    onOutsideClick: () => void;
    selectedVehicleIds: string[];
    vehicles: ElectricVehicle[];
}

export interface AvgDrivingConsumptionChartMonth {
    date: string;
    averageAuxiliaryConsumption: number;
    averagePowertrainConsumption: number;
    averageTotalConsumption: number;
    averageAmsConsumption: number;
    vehiclesQuantity: number;
}

export interface VehicleStatistics {
    vehicleId: string;
    history: VehicleHistory[];
}

export interface VehicleHistory {
    date: string;
    drivenDistance: number;
    energyConsumption: number;
    powertrainEnergyConsumption: number;
    auxiliaryEnergyConsumption: number;
    amsEnergyConsumption: number;
    recuperatedEnergy: number;
}

export interface MultiselectOption {
    id: string;
    label: string | JSX.Element;
    icon?: JSX.Element;
    selected?: boolean;
}

export enum MultiselectOptionsType {
    ALL_OPTIONS = 'ALL_OPTIONS',
}

export enum KpiEnum {
    AV_DRIVING_CONSUMPTION = 'AV_DRIVING_CONSUMPTION',
    AV_RECUPERATION = 'AV_RECUPERATION',
    AV_MILEAGE = 'AV_MILEAGE',
}
