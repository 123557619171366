import { ChargingConfigurationStatusEnum, ChargingModeEnum } from '../types';
import { Weekday } from '../components/details/vehicleDetails/types';

export interface NextDepartureTimeStatusResponse {
    status: ChargingConfigurationStatusEnum;
    departure_time: string;
}

export interface TimerResponse {
    id: string;
    weekdays: Weekday[];
    departure_time_hour: number;
    departure_time_minute: number;
    climate_mode: boolean;
    charge_until?: number;
    ready_to_drive_duration: number;
    zone_id: string;
    weekly_repeat: boolean;
    enabled: boolean;
    next_departures_time_status: NextDepartureTimeStatusResponse[];
    comfort_timer: boolean;
}

export enum VehicleChargingConfigurationSelectedEnum {
    IMMEDIATE = 'IMMEDIATE',
    TIMER = 'TIMER',
}

export interface VehicleChargingConfigurationResponse {
    asset_id: string;
    charging_mode: ChargingModeEnum;
    configurations: {
        selected: VehicleChargingConfigurationSelectedEnum;
        timer: TimerResponse[];
        immediate: {
            status: ChargingConfigurationStatusEnum;
            climate_mode: boolean;
            charge_until: number;
        };
    };
    _actions: {
        post_immediate_configurations?: {
            href: string;
        };
        post_timer_configurations?: {
            href: string;
        };
    };
}

export interface ImmediateConfigurationRequest {
    climate_mode: boolean;
    charge_until: number;
}

export interface TimerRequest {
    enabled: boolean;
    weekdays: Weekday[];
    climate_mode: boolean;
    weekly_repeat: boolean;
    departure_time_hour: number;
    departure_time_minute: number;
    charge_until?: number;
    ready_to_drive_duration: number;
    zone_id: string;
    comfort_timer: boolean;
}

export interface TimerPatchRequest {
    op: string;
    path: string;
    value?: boolean;
}
