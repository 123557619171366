import { WhatsNewDataItem } from './types';
import newHistory from '../../../../../assets/images/whatsNew/new-history.gif';
import notificationBell from '../../../../../assets/images/whatsNew/notification-bell.png';
import improvedDownload from '../../../../../assets/images/whatsNew/improved-download.png';
import immediateCharging from '../../../../../assets/images/whatsNew/immediate-charging.gif';
import timerCharging from '../../../../../assets/images/whatsNew/timer-charging.gif';
import comfortTimer from '../../../../../assets/images/whatsNew/comfort-timer.gif';
import fleetEvents from '../../../../../assets/images/whatsNew/fleet-events.gif';
import { FeatureToggles } from '../../../../../../../configuration/featureToggle/featureToggles';

export const whatsNewData: WhatsNewDataItem[] = [
    {
        version: 'e4c.whatsNew.version.fleetEvents',
        date: '28.01.2025',
        content: {
            title: {
                value: 'e4c.whatsNew.fleetEvents.title',
            },
            items: [
                {
                    description: {
                        value: 'e4c.whatsNew.fleetEvents.description',
                    },
                    image: {
                        value: fleetEvents,
                    },
                },
            ],
        },
    },
    {
        version: 'e4c.whatsNew.version.vehicleDetails',
        date: '08.01.2025',
        content: {
            title: {
                value: 'e4c.whatsNew.vehicleDetails.title',
            },
            items: [
                {
                    description: {
                        value: 'e4c.whatsNew.vehicleDetails.description',
                    },
                    footer: {
                        value: 'e4c.whatsNew.vehicleDetails.footer',
                    },
                    image: {
                        value: immediateCharging,
                    },
                },
                {
                    description: {
                        value: 'e4c.whatsNew.vehicleDetails.secondDescription',
                    },
                    image: {
                        value: timerCharging,
                    },
                    footer: {
                        value: 'e4c.whatsNew.vehicleDetails.secondFooter',
                    },
                },
                {
                    description: {
                        value: 'e4c.whatsNew.vehicleDetails.thirdDescription',
                    },
                    image: {
                        value: comfortTimer,
                    },
                },
            ],
        },
    },
    {
        version: 'e4c.whatsNew.version.improvedDownload',
        date: '21.08.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.improvedDownload.title',
            },
            items: [
                {
                    description: {
                        value: 'e4c.whatsNew.improvedDownload.description',
                    },
                    image: {
                        value: improvedDownload,
                    },
                },
            ],
        },
        toggle: FeatureToggles.REDESIGN_DOWNLOAD,
    },
    {
        version: 'e4c.whatsNew.version.changevalues',
        date: '19.06.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.changevalues.title',
            },
            items: [
                {
                    description: {
                        value: 'e4c.whatsNew.changevalues.description',
                    },
                },
            ],
        },
    },
    {
        version: 'e4c.whatsNew.version.notificationBell',
        date: '08.05.2024',
        content: {
            title: {
                value: 'e4c.whatsNew.notificationBell.title',
            },
            items: [
                {
                    description: {
                        value: 'e4c.whatsNew.notificationBell.description',
                    },
                    image: {
                        value: notificationBell,
                    },
                },
            ],
        },
    },
    {
        version: 'e4c.whatsNew.version.history',
        date: '28.11.2023',
        content: {
            title: {
                value: 'e4c.whatsNew.history.title',
            },
            items: [
                {
                    description: {
                        value: 'e4c.whatsNew.history.description',
                    },
                    image: {
                        value: newHistory,
                    },
                    footer: {
                        value: 'e4c.whatsNew.bookingTypeAvailabilityM',
                    },
                },
            ],
        },
        toggle: FeatureToggles.NEW_HISTORY,
    },
];
