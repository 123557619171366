import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { cloneDeep } from 'lodash';
import Switch from '@rio-cloud/rio-uikit/Switch';
import { Timer, Weekday } from '../vehicleChargingConfigurationTypes';
import { ChargingConfigurationStatusEnum } from '../../../../../types';
import { convertDepartureTime } from '../../vehicleDetailsHelper';
import NextOccurrences from './NextOccurrences';
import { mapShortWeekdayToIntl } from '../../../../../../common/enums/intl/weekdays';
import { VehicleDetailsContext } from '../../../../../../../../providers/contexts';

const TimerView = ({ timer }: { timer: Timer }) => {
    const intl = useIntl();
    const {
        isReadOnly,
        updateTimerEnabledStatus,
        removeTimer,
        showEditTimerForm,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        setUnsavedTimers,
        timers,
        dialog,
        unsavedHasControl,
    } = useContext(VehicleDetailsContext);

    const getDays = () =>
        timer.weekdays
            .map((day: Weekday) => intl.formatMessage({ id: mapShortWeekdayToIntl(day) }))
            .join(' | ')
            .toLowerCase();
    const hasRejectedStatus = timer.nextDeparturesTimeStatus.some(
        elem => elem.status === ChargingConfigurationStatusEnum.REJECTED
    );
    const formattedDepartureTime = convertDepartureTime(
        `${timer.departureTimeHour}:${timer.departureTimeMinute}`,
        timer.zoneId
    );

    const handleEdit = () => {
        if (hasUnsavedChanges) {
            dialog.openDialog(handleDialogConfirm);
        } else {
            showEditTimerForm(timer);
        }
    };

    const handleDialogConfirm = () => {
        setUnsavedTimers(cloneDeep(timers));
        setHasUnsavedChanges(false);
        showEditTimerForm(timer);
    };

    return (
        <div
            className={`${
                hasRejectedStatus
                    ? 'border-color-danger callout callout-danger'
                    : 'padding-left-25 padding-right-20 padding-top-20 padding-bottom-20'
            } border margin-top-20`}
            data-testid="timer"
        >
            <div className="margin-10">
                <div className="margin-bottom-10">
                    <div className="display-flex justify-content-between">
                        <div>
                            <div className="display-flex">
                                <span className="text-bold text-capitalize">{getDays()}</span>
                                <span className="margin-left-10">{formattedDepartureTime}</span>
                            </div>
                            {timer.weeklyRepeat && (
                                <div className="text-size-12">
                                    <FormattedMessage id="e4c.vehicle.details.weeklyRepeat" />
                                </div>
                            )}
                        </div>
                        <div className="display-flex align-items-center">
                            {!isReadOnly && unsavedHasControl && (
                                <>
                                    <button
                                        data-testid="edit-timer"
                                        className="btn btn-action text-color-black text-size-18 rioglyph rioglyph-pencil"
                                        onClick={handleEdit}
                                    />
                                    <button
                                        data-testid="delete-timer"
                                        className="btn btn-action text-color-black text-size-18 rioglyph rioglyph-trash margin-right-10"
                                        onClick={() => {
                                            removeTimer(timer.id);
                                        }}
                                    />
                                </>
                            )}
                            <Switch
                                data-testid="enable-timer"
                                onChange={() => {
                                    updateTimerEnabledStatus(timer.id, !timer.enabled);
                                }}
                                checked={timer.enabled}
                                disabled={isReadOnly || !unsavedHasControl}
                            />
                        </div>
                    </div>
                </div>
                <div className="display-flex">
                    {timer.comfortTimer ? (
                        <div
                            className="label label-secondary label-filled label-condensed"
                            style={{ maxHeight: '30px' }}
                        >
                            <FormattedMessage id="e4c.vehicle.details.comfortTimer.label" />
                        </div>
                    ) : (
                        <div className="label label-info label-filled label-condensed" style={{ maxHeight: '30px' }}>
                            <FormattedMessage id="e4c.vehicle.details.timerCharging.label" />
                        </div>
                    )}
                    <span className="margin-left-10 margin-right-10">|</span>
                    {!timer.comfortTimer && (
                        <>
                            <span>
                                <FormattedMessage id="e4c.vehicle.details.chargeUntil.title" /> {timer.chargeUntil}%
                            </span>
                            <span className="margin-left-10 margin-right-10">|</span>
                        </>
                    )}
                    <span>
                        <FormattedMessage id="e4c.vehicle.details.climateControl.title" />
                        {timer.climateMode ? ' ON' : ' OFF'}
                    </span>
                </div>
                <NextOccurrences
                    weekdays={timer.weekdays}
                    departureTime={formattedDepartureTime}
                    repeatTimer={timer.weeklyRepeat}
                    showStatus={true}
                    timersSentToVehicle={timer.nextDeparturesTimeStatus}
                />
            </div>
        </div>
    );
};

export default TimerView;
