import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { VehicleDetailsContext } from '../../../../../../../providers/contexts';
import {
    usePatchTimerConfigurations,
    usePostImmediateConfigurations,
    usePostNoControlConfigurations,
} from '../../../../queries/useVehicleChargingAPI';
import { State } from '../../../../../../../types';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../../../../../../../configuration';
import { cloneDeep } from 'lodash';
import { checkAllDisabledOrDeleted } from './utils';
import { SwitchToImmediateChargingReason } from '../../../types';
import SwitchToImmediateChargingDialog from '../../VehicleDetailsDialogs/SwitchToImmediateChargingDialog/SwitchToImmediateChargingDialog';

const SaveChargingSettings = ({ assetId }: { assetId: string }) => {
    const accessToken = useSelector((state: State) => getAccessToken(state));

    const [dialogState, setDialogState] = useState({
        isVisible: false,
        reason: null as SwitchToImmediateChargingReason | null,
        wasShown: false,
    });

    const {
        activeTab: chargingTab,
        setActiveTab,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        changedChargingTab,
        setSendingChargingConfigFailed,
        setSendingChargingConfigSucceeded,
        immediateChargingChanges,
        timers,
        setTimers,
        unsavedTimers,
        showSetupForm,
        setChangedChargingTab,
        setImmediateConfiguration,
        unsavedHasControl,
        hasControl,
        setHasControl,
    } = useContext(VehicleDetailsContext);

    const { mutate: saveImmediate } = usePostImmediateConfigurations(accessToken, assetId);

    const { mutate: saveNoControl } = usePostNoControlConfigurations(accessToken, assetId);

    const { mutate: saveTimers } = usePatchTimerConfigurations(accessToken, assetId, timers, unsavedTimers);

    const isSaveEnabled = () => {
        if (showSetupForm) {
            return false;
        }
        if (hasUnsavedChanges) {
            return true;
        }
        if (changedChargingTab) {
            if (chargingTab === 'timerTab') {
                return unsavedTimers.some(timer => timer.enabled);
            }
            return true;
        }
        return unsavedHasControl !== hasControl;
    };

    const saveImmediateChanges = () => {
        saveImmediate(immediateChargingChanges, {
            onSuccess: () => {
                setHasUnsavedChanges(false);
                setChangedChargingTab(false);
                setImmediateConfiguration({
                    climateMode: immediateChargingChanges.climateMode,
                    chargeUntil: immediateChargingChanges.chargeUntil,
                });
                setSendingChargingConfigSucceeded(true);
                setSendingChargingConfigFailed(false);
            },
            onError: () => {
                setSendingChargingConfigSucceeded(false);
                setSendingChargingConfigFailed(true);
            },
        });
    };

    const saveTimerChanges = () => {
        saveTimers(undefined, {
            onSuccess: () => {
                setHasUnsavedChanges(false);
                setChangedChargingTab(false);
                setTimers(cloneDeep(unsavedTimers));
                setSendingChargingConfigSucceeded(true);
                setSendingChargingConfigFailed(false);

                if (dialogState.wasShown) {
                    setActiveTab('immediateTab');
                }
                setDialogState(prev => ({ ...prev, wasShown: false }));
            },
            onError: () => {
                setSendingChargingConfigSucceeded(false);
                setSendingChargingConfigFailed(true);
            },
        });
    };

    const handleSave = () => {
        if (unsavedHasControl) {
            if (chargingTab === 'timerTab') {
                const reason = checkAllDisabledOrDeleted(unsavedTimers);
                if (reason !== null) {
                    setDialogState({ isVisible: true, reason, wasShown: true });
                } else {
                    saveTimerChanges();
                }
            } else {
                saveImmediateChanges();
            }
        } else {
            saveNoControl(undefined, {
                onSuccess: () => {
                    setSendingChargingConfigSucceeded(true);
                    setHasControl(false);
                },
                onError: () => {
                    setSendingChargingConfigSucceeded(false);
                    setSendingChargingConfigFailed(true);
                },
            });
        }
    };

    return (
        <>
            <button
                className="btn btn-primary display-flex margin-left-auto"
                type="button"
                id="save-button"
                disabled={!isSaveEnabled()}
                onClick={handleSave}
            >
                <FormattedMessage id="e4c.global.save" />
            </button>
            <SwitchToImmediateChargingDialog
                show={dialogState.isVisible}
                onCancel={() => setDialogState(prev => ({ ...prev, isVisible: false }))}
                onConfirm={() => {
                    saveTimerChanges();
                    setDialogState(prev => ({ ...prev, isVisible: false }));
                }}
                reason={dialogState.reason}
            />
        </>
    );
};

export default SaveChargingSettings;
