import { PropsWithChildren, useState } from 'react';
import { VehicleDetailsContext } from './contexts';
import {
    ImmediateConfiguration,
    Timer,
} from '../features/app/vehicleCharging/components/details/vehicleDetails/ChargingMode/vehicleChargingConfigurationTypes';
import { useUnsavedChangesDialog } from '../features/app/vehicleCharging/components/details/vehicleDetails/ChargingMode/useUnsavedChangesDialog';
import { convertDepartureTime } from '../features/app/vehicleCharging/components/details/vehicleDetails/vehicleDetailsHelper';
import { TimerConfig } from '../features/app/vehicleCharging/components/details/vehicleDetails/ChargingMode/TimerCharging/TimerSetupForm/TimerSetupForm';
import { cloneDeep } from 'lodash';

const VehicleDetailsProvider = ({ children }: PropsWithChildren) => {
    const getDefaultTimerConfig = (isComfortTimer: boolean): TimerConfig => ({
        weekdays: [],
        departureTime: '12:00',
        climateMode: isComfortTimer,
        chargeUntil: !isComfortTimer ? 100 : undefined,
        readyToDriveDuration: 30,
        weeklyRepeat: true,
        enabled: true,
        comfortTimer: isComfortTimer,
    });
    const [activeTab, setActiveTab] = useState('');
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [sendingChargingConfigFailed, setSendingChargingConfigFailed] = useState(false);
    const [sendingChargingConfigSucceeded, setSendingChargingConfigSucceeded] = useState(false);
    const [changedChargingTab, setChangedChargingTab] = useState(false);
    const [immediateConfiguration, setImmediateConfiguration] = useState<ImmediateConfiguration | undefined>(undefined);
    const [immediateChargingChanges, setImmediateChargingChanges] = useState({ chargeUntil: 100, climateMode: false });
    const [timers, setTimers] = useState<Timer[] | []>([]);
    const [unsavedTimers, setUnsavedTimers] = useState<Timer[] | []>([]);
    const [showSetupForm, setShowSetupForm] = useState(false);
    const [editingTimer, setEditingTimer] = useState<TimerConfig>(getDefaultTimerConfig(false));
    const dialog = useUnsavedChangesDialog();
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [hasControl, setHasControl] = useState(true);
    const [unsavedHasControl, setUnsavedHasControl] = useState(true);

    const showNewTimerForm = (isComfortTimer: boolean) => {
        const newTimer = getDefaultTimerConfig(isComfortTimer);
        setEditingTimer(newTimer);
        setShowSetupForm(true);
    };

    const showEditTimerForm = (timer: Timer) => {
        const timerConfig = {
            id: timer.id,
            weekdays: timer.weekdays,
            departureTime: convertDepartureTime(
                `${timer.departureTimeHour}:${timer.departureTimeMinute}`,
                timer.zoneId
            ),
            climateMode: timer.climateMode,
            chargeUntil: timer.chargeUntil,
            readyToDriveDuration: timer.readyToDriveDuration,
            weeklyRepeat: timer.weeklyRepeat,
            enabled: timer.enabled,
            comfortTimer: timer.comfortTimer,
        };
        setEditingTimer(timerConfig);
        setShowSetupForm(true);
    };

    const hideTimerForm = () => {
        getDefaultTimerConfig(false);
        setShowSetupForm(false);
    };

    const updateTimerEnabledStatus = (timerId: string, value: boolean) => {
        const updatedTimers = unsavedTimers.map(timer => {
            if (timer.id === timerId) {
                timer.enabled = value;
            }
            return timer;
        });
        setUnsavedTimers(updatedTimers);
        setHasUnsavedChanges(hasTimersChanged(updatedTimers));
    };

    const hasTimersChanged = (updatedTimers: Timer[]): boolean => {
        return timers.some(timer => {
            return !updatedTimers.some(
                updatedTimer => timer.id === updatedTimer.id && timer.enabled === updatedTimer.enabled
            );
        });
    };

    const removeTimer = (timerId: string) => {
        const filteredTimerList = unsavedTimers.filter(timer => timer.id !== timerId);
        setUnsavedTimers(filteredTimerList);
        setHasUnsavedChanges(hasTimersChanged(filteredTimerList));
    };

    const resetContext = () => {
        setHasUnsavedChanges(false);
        setSendingChargingConfigFailed(false);
        setSendingChargingConfigSucceeded(false);
        setChangedChargingTab(false);
        setImmediateConfiguration(undefined);
        setImmediateChargingChanges({ chargeUntil: 100, climateMode: false });
        setTimers([]);
        setUnsavedTimers([]);
        setShowSetupForm(false);
        setEditingTimer(getDefaultTimerConfig(false));
    };

    const resetUnsavedChanges = () => {
        setHasUnsavedChanges(false);
        setChangedChargingTab(false);
        setImmediateChargingChanges(cloneDeep(immediateConfiguration || { chargeUntil: 100, climateMode: false }));
        setUnsavedTimers(cloneDeep(timers));
        setShowSetupForm(false);
        setEditingTimer(getDefaultTimerConfig(false));
    };

    return (
        <VehicleDetailsContext.Provider
            value={{
                activeTab,
                setActiveTab,
                hasUnsavedChanges,
                setHasUnsavedChanges,
                sendingChargingConfigFailed,
                setSendingChargingConfigFailed,
                sendingChargingConfigSucceeded,
                setSendingChargingConfigSucceeded,
                changedChargingTab,
                setChangedChargingTab,
                immediateConfiguration,
                setImmediateConfiguration,
                immediateChargingChanges,
                setImmediateChargingChanges,
                timers,
                setTimers,
                unsavedTimers,
                setUnsavedTimers,
                updateTimerEnabledStatus,
                showSetupForm,
                showEditTimerForm,
                hideTimerForm,
                showNewTimerForm,
                editingTimer,
                removeTimer,
                dialog,
                isReadOnly,
                setIsReadOnly,
                resetContext,
                hasControl,
                setHasControl,
                unsavedHasControl,
                setUnsavedHasControl,
                resetUnsavedChanges,
            }}
        >
            {children}
        </VehicleDetailsContext.Provider>
    );
};

export default VehicleDetailsProvider;
