import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import NoData from '@rio-cloud/rio-uikit/NoData';
import { ProductVersion } from '../../../common/vehicles/productVersion';
import VehicleType from '../../../common/vehicles/VehicleType';
import BookNow from '../../../common/components/Tables/BookNow/BookNow';
import VehicleIdentification from '../../../common/components/Tables/VehicleIdentification/VehicleIdentification';
import { ChargeStats } from '../types';
import { roundToTwoDecimalPlaces } from '../helpers/utils';

interface VehicleStatsHeaderProps {
    name: string;
    productVersion: ProductVersion;
    vehicleType: VehicleType;
    assetId: string;
    stats?: ChargeStats;
}

interface StatsProps {
    titleId: string;
    value: string | ReactElement;
    rightBorder?: boolean;
}

const Stats = ({ titleId, value, rightBorder }: StatsProps): ReactElement => {
    return (
        <div className={`padding-x-10 margin-y-10 ${rightBorder ? 'border border-right-only' : ''}`}>
            <FormattedMessage id={titleId} />
            <div className="text-right">{value}</div>
        </div>
    );
};

const EnergyValue = ({ value }: { value?: number }): ReactElement => {
    return value !== undefined ? (
        <>
            <span className="text-size-18 text-medium">{roundToTwoDecimalPlaces(value)}</span>{' '}
            <span className="text-size-14"> kWh</span>
        </>
    ) : (
        <NoData className="text-size-18 justify-content-end" text="No data" />
    );
};

const BatteryTemperatureWarning = ({ value }: { value: number }) => {
    return (
        <div className="display-flex justify-content-end align-items-center">
            <span className="rioglyph rioglyph-warning-sign text-color-warning padding-right-5" />
            <span className="text-size-18">{value}</span>
        </div>
    );
};

export const VehicleStatsHeader = ({ name, productVersion, assetId, vehicleType, stats }: VehicleStatsHeaderProps) => {
    return (
        <div className="display-flex justify-content-between height-50" data-testid="vehicle-stats-header">
            <VehicleIdentification name={name} productVersion={productVersion} vehicleType={vehicleType} />
            {productVersion === ProductVersion.EMANAGER_M && stats ? (
                <div className="display-flex justify-content-between align-items-center">
                    {stats.batteryTemperatureWarningCount > 0 && (
                        <Stats
                            titleId="e4c.history.vehicle.expander.batteryTemperature"
                            value={<BatteryTemperatureWarning value={stats.batteryTemperatureWarningCount} />}
                            rightBorder
                        />
                    )}
                    <Stats
                        titleId="e4c.history.vehicle.expander.totalEnergyCharged"
                        value={<EnergyValue value={stats.totalEnergyCharged} />}
                        rightBorder
                    />
                    <Stats
                        titleId="e4c.history.vehicle.expander.totalEnergyConsumed"
                        value={<EnergyValue value={stats.totalEnergyConsumed} />}
                    />
                </div>
            ) : (
                <BookNow assetId={assetId} productVersion={productVersion} />
            )}
        </div>
    );
};
