import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import detailEmanagerControlPanelModalImage from '../../../../../assets/images/detail-emanager-control-panel-modal.png';
import UserManualPage from '../components/UserManualPage';

const ComfortTimerItem = (): ReactElement => {
    const intl = useIntl();

    return (
        <UserManualPage
            title={<FormattedMessage id="e4c.userManual.details.emanagerControl.title" />}
            dataTestId="emanager-control-item"
        >
            <p>
                <FormattedMessage id="e4c.userManual.details.emanagerControl.titleDescription" />
            </p>
            <p>
                <span className="text-bold">
                    <FormattedMessage id="e4c.userManual.details.emanagerControl.important" />
                </span>{' '}
                <FormattedMessage id="e4c.userManual.details.emanagerControl.important.description" />
            </p>
            <p>
                <span className="text-bold">
                    <FormattedMessage id="e4c.userManual.details.emanagerControl.relevantVehiclesMessage" />
                </span>
            </p>
            <div className="display-flex margin-bottom-20">
                <img
                    alt={intl.formatMessage({ id: 'e4c.userManual.altText.emanagerControl.toggle' })}
                    src={detailEmanagerControlPanelModalImage}
                    className="margin-right-25"
                    height="350"
                />
            </div>
            <div className="counter color-onboarding size-small filled">
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id="e4c.userManual.details.emanagerControl.switchOn" />
                        </span>{' '}
                        <FormattedMessage id="e4c.userManual.details.emanagerControl.switchOn.description" />
                    </p>
                </div>
                <div>
                    <p className="margin-bottom-0 margin-top-0">
                        <span className="text-bold">
                            <FormattedMessage id="e4c.userManual.details.emanagerControl.switchOff" />
                        </span>{' '}
                        <FormattedMessage id="e4c.userManual.details.emanagerControl.switchOff.description" />
                    </p>
                </div>
            </div>
        </UserManualPage>
    );
};

export default ComfortTimerItem;
