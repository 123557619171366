import ChargingStatus from '../../models/enums/ChargingStatus';
import { FormattedMessage } from 'react-intl';
import StatsWidgets from '@rio-cloud/rio-uikit/StatsWidgets';
import { SingleFilterPanel } from './SingleFilterPanel';
import { DoubleFilterPanel } from './DoubleFilterPanel';
import VehicleType from '../../../common/vehicles/VehicleType';
import { updateFilterValues } from './utils';
import { createGoogleAnalyticsEvent, gtmUtil, TrackingAction, TrackingCategory } from '../../../utils/googleTagManager';
import { useDispatch } from 'react-redux';
import { chargingStatusFilterChanged } from '../../../actions/vehicles/Vehicles.actions';
import SkeletonStatusVehicleTable from '../Skeleton/SkeletonStatusVehicleTable';
import { VehicleChargingData } from '../../models/VehicleChargingData';

interface FilterWidgetProps {
    filterByChargingStatusWidget: (ChargingStatus | undefined)[];
    vehicles: VehicleChargingData[];
    isLoading: boolean;
}

const hasOnlyDesiredType = (vehicleType: VehicleType, vehicles: VehicleChargingData[]): boolean => {
    return vehicles.length !== 0 && vehicles.filter(v => v.assetInfo.type === vehicleType).length === vehicles.length;
};

export const FilterWidget = ({ filterByChargingStatusWidget, vehicles, isLoading }: FilterWidgetProps): JSX.Element => {
    const dispatch = useDispatch();

    const onFilterSingleFilterChargingStatusChange = (
        chargingStatusReceived: (ChargingStatus | undefined)[] | undefined
    ): void => {
        onFilterChargingStatusChange(chargingStatusReceived, []);
    };

    const onFilterChargingStatusChange = (
        chargingStatusReceived: (ChargingStatus | undefined)[] | undefined,
        relatedChargingStatus: (ChargingStatus | undefined)[]
    ): void => {
        const updatedFilter: (ChargingStatus | undefined)[] = chargingStatusReceived
            ? updateFilterValues(chargingStatusReceived, relatedChargingStatus, filterByChargingStatusWidget)
            : [];
        gtmUtil.pushGaEvent(
            createGoogleAnalyticsEvent(
                TrackingCategory.VEHICLES_TAB,
                TrackingAction.FILTER_PANEL_USAGE,
                updatedFilter.join(', ')
            )
        );
        dispatch(chargingStatusFilterChanged(updatedFilter));
    };

    const onResetFilter = (): void => {
        dispatch(chargingStatusFilterChanged([]));
    };

    if (isLoading) {
        return <SkeletonStatusVehicleTable />;
    }

    return (
        <StatsWidgets>
            {hasOnlyDesiredType(VehicleType.VAN, vehicles) ? (
                <SingleFilterPanel
                    chargingStatus={[ChargingStatus.UNKNOWN, undefined]}
                    chargingStatusFilterValues={filterByChargingStatusWidget}
                    color={'text-color-danger'}
                    dataTestId={'fw-unknown'}
                    dataTestIdHeader={'fw-unknown-failure-header'}
                    lblType={lblUnknown}
                    onFilterChargingStatusChange={onFilterSingleFilterChargingStatusChange}
                    onResetFilter={onResetFilter}
                    plugIcon={'rioglyph rioglyph-plugged-on'}
                    vehicles={vehicles}
                />
            ) : (
                <DoubleFilterPanel
                    firstChargingStatus={[ChargingStatus.UNKNOWN, undefined]}
                    chargingStatusFilterValues={filterByChargingStatusWidget}
                    firstTextColor={'text-color-danger'}
                    firstDataTestId={'fw-unknown'}
                    dataTestIdHeader={'fw-unknown-failure-header'}
                    dataTestIdSpacer={'fw-unknown-failure-spacer'}
                    firstLabelType={lblUnknown}
                    onFilterChargingStatusChange={onFilterChargingStatusChange}
                    onResetFilter={onResetFilter}
                    plugIcon={'rioglyph rioglyph-plugged-on'}
                    secondChargingStatus={[ChargingStatus.FAILURE]}
                    secondDataTestId={'fw-failure'}
                    secondTextColor={'text-color-danger'}
                    secondLabelType={lblFailure}
                    vehicles={vehicles}
                />
            )}
            <DoubleFilterPanel
                firstChargingStatus={[ChargingStatus.CHARGED]}
                chargingStatusFilterValues={filterByChargingStatusWidget}
                firstTextColor={'text-color-success'}
                firstDataTestId={'fw-charged'}
                dataTestIdHeader={'fw-charged-charging-header'}
                dataTestIdSpacer={'fw-charged-charging-spacer'}
                firstLabelType={lblCharged}
                onFilterChargingStatusChange={onFilterChargingStatusChange}
                onResetFilter={onResetFilter}
                plugIcon={'rioglyph rioglyph-plugged-on'}
                secondChargingStatus={[ChargingStatus.CHARGING, ChargingStatus.CHARGING_LONGPRESS]}
                secondDataTestId={'fw-charging'}
                secondTextColor={'text-color-success'}
                secondLabelType={lblCharging}
                vehicles={vehicles}
            />
            <SingleFilterPanel
                chargingStatus={[ChargingStatus.CONNECTED]}
                chargingStatusFilterValues={filterByChargingStatusWidget}
                color={'text-color-warning'}
                dataTestId={'fw-connected'}
                dataTestIdHeader={'fw-connected-header'}
                lblType={lblConnected}
                onFilterChargingStatusChange={onFilterSingleFilterChargingStatusChange}
                onResetFilter={onResetFilter}
                plugIcon={'rioglyph rioglyph-plugged-on'}
                vehicles={vehicles}
            />
            <SingleFilterPanel
                chargingStatus={[ChargingStatus.DISCONNECTED]}
                chargingStatusFilterValues={filterByChargingStatusWidget}
                color={'text-color-gray'}
                dataTestId={'fw-disconnected'}
                dataTestIdHeader={'fw-disconnected-header'}
                lblType={lblDisconnected}
                onFilterChargingStatusChange={onFilterSingleFilterChargingStatusChange}
                onResetFilter={onResetFilter}
                plugIcon={'rioglyph rioglyph-plugged-off'}
                vehicles={vehicles}
            />
        </StatsWidgets>
    );
};

export const lblCharged = <FormattedMessage id={'e4c.vehicles.table.chargingStatus.charged'} />;
export const lblCharging = <FormattedMessage id={'e4c.vehicles.table.chargingStatus.charging'} />;
export const lblUnknown = <FormattedMessage id={'e4c.vehicles.table.chargingStatus.noSignal'} />;
export const lblFailure = <FormattedMessage id={'e4c.vehicles.table.chargingStatus.failure'} />;
export const lblConnected = <FormattedMessage id={'e4c.vehicles.table.chargingStatus.connected'} />;
export const lblDisconnected = <FormattedMessage id={'e4c.vehicles.table.chargingStatus.disconnected'} />;
export const lblReadyToDrive = <FormattedMessage id={'e4c.overview.vehicleStatus.vehiclesReadyToDrive'} />;
export const lblDriving = <FormattedMessage id={'e4c.overview.vehicleStatus.vehiclesDriving.title'} />;
