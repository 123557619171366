import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import classNames from 'classnames';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import { PLACEMENT } from '@rio-cloud/rio-uikit/values/Placement';
import TooltipRef from '../../../../../common/components/Tooltip/Tooltip';
import { State } from '../../../../../../../types';
import { getAccessToken } from '../../../../../../../configuration';
import { ASSET_NOT_FOUND_ERROR, useGetVehicleChargingConfiguration } from '../../../../queries/useVehicleChargingAPI';
import { VehicleDetailsContext } from '../../../../../../../providers/contexts';
import { ChargingModeEnum } from '../../../../types';
import ErrorState from '../../../../../common/components/states/ErrorState';
import { ChargingTab, createChargingTabs } from './createChargingTabs';
import VehiclesDetailsError from '../../VehicleDetailsError/VehicleDetailsError';
import { ErrorCodeEnum } from '../../../../../common/enums/ErrorCode';
import { isReadOnlyUser } from './utils';
import { Switch } from '@rio-cloud/rio-uikit';
import VehicleModel from '../../../../models/enums/VehicleModel';
import { useFeatureToggleByFleetOrByCustom } from '../../../../../common/hooks/useFeatureToggle';
import { FeatureToggles } from '../../../../../../../configuration/featureToggle/featureToggles';

const ChargingMode = ({ assetId, vehicleModel }: { assetId: string; vehicleModel: VehicleModel }) => {
    const intl = useIntl();
    const accessToken = useSelector((state: State) => getAccessToken(state));
    const {
        isPending: isLoading,
        isError,
        error,
        vehicleChargingConfiguration,
    } = useGetVehicleChargingConfiguration(accessToken, assetId);
    const {
        activeTab,
        setActiveTab,
        hasUnsavedChanges,
        setHasUnsavedChanges,
        setChangedChargingTab,
        hideTimerForm,
        setTimers,
        timers,
        setUnsavedTimers,
        setImmediateConfiguration,
        resetContext,
        dialog,
        isReadOnly,
        setIsReadOnly,
        setHasControl,
        unsavedHasControl,
        setUnsavedHasControl,
        resetUnsavedChanges: resetUnsavedChanges,
    } = useContext(VehicleDetailsContext);

    const { value: eManagerControl } = useFeatureToggleByFleetOrByCustom(
        FeatureToggles.EMANAGER_CONTROL,
        false,
        'asset',
        assetId
    );
    const showEmanagerControl = vehicleModel === VehicleModel.TRUE && eManagerControl;
    const [chargingTabs, setChargingTabs] = useState<ChargingTab[]>([]);

    // Reset the context if assetId changes
    useEffect(() => {
        resetContext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assetId]);

    useEffect(() => {
        if (vehicleChargingConfiguration) {
            setImmediateConfiguration(cloneDeep(vehicleChargingConfiguration.immediateConfiguration));
            setTimers(cloneDeep(vehicleChargingConfiguration.timers || []));
            setUnsavedTimers(cloneDeep(vehicleChargingConfiguration.timers || []));
            setIsReadOnly(isReadOnlyUser(vehicleChargingConfiguration));
            const calculatedHasControl = vehicleChargingConfiguration?.hasControl || !showEmanagerControl;
            setHasControl(calculatedHasControl);
            setUnsavedHasControl(calculatedHasControl);
        }
    }, [
        setHasControl,
        setImmediateConfiguration,
        setIsReadOnly,
        setTimers,
        setUnsavedHasControl,
        setUnsavedTimers,
        showEmanagerControl,
        vehicleChargingConfiguration,
    ]);

    // Handle vehicleDetailsData changes to set up tabs and activeTab
    useEffect(() => {
        if (vehicleChargingConfiguration && !isLoading) {
            const tabs = createChargingTabs(assetId);
            setChargingTabs(tabs);
            const chargingMode = vehicleChargingConfiguration.chargingMode;
            const initialTab = chargingMode === ChargingModeEnum.TIMER ? tabs[1]?.id : tabs[0]?.id;
            setActiveTab(initialTab || '');
            setChangedChargingTab(false);
        }
    }, [vehicleChargingConfiguration, assetId, setChangedChargingTab, isLoading]);

    const handleSwitchTabs = (tabId: string) => {
        if (hasUnsavedChanges) {
            dialog.openDialog(() => handleDialogConfirm(tabId));
        } else {
            switchToTab(tabId);
        }
    };

    const handleDialogConfirm = (tabId: string) => {
        if (tabId) {
            switchToTab(tabId);
            setUnsavedTimers(cloneDeep(timers));
            setHasUnsavedChanges(false);
        }
    };

    const changeEmanagerControl = (value: boolean) => {
        setUnsavedHasControl(value);
        if (!value) {
            resetUnsavedChanges();
            const chargingMode = vehicleChargingConfiguration?.chargingMode;
            const initialTab = chargingMode === ChargingModeEnum.TIMER ? chargingTabs[1]?.id : chargingTabs[0]?.id;
            setActiveTab(initialTab);
        }
    };

    const switchToTab = (tabId: string) => {
        setActiveTab(tabId);
        const chargingMode = vehicleChargingConfiguration?.chargingMode;
        const initialTab = chargingMode === ChargingModeEnum.TIMER ? chargingTabs[1]?.id : chargingTabs[0]?.id;
        setChangedChargingTab(tabId !== initialTab);
        hideTimerForm();
    };

    if (isLoading) {
        return <Spinner text={intl.formatMessage({ id: 'e4c.spinner.loading' })} />;
    }
    if ((error as Error)?.message?.includes(ASSET_NOT_FOUND_ERROR)) {
        return <VehiclesDetailsError errorCode={ErrorCodeEnum.NOT_FOUND} />;
    }
    if (isError || !vehicleChargingConfiguration) {
        return <ErrorState stateProps={{ type: 'general', withoutBorder: true }} />;
    }

    return (
        <div className="margin-20" data-testid="charging-mode">
            {showEmanagerControl && (
                <div
                    className={`${unsavedHasControl ? 'border-color-light bg-lightest' : 'label-warning border-color-warning'} border
                    rounded display-flex justify-content-between align-items-center padding-20 margin-bottom-20`}
                >
                    <div className="padding-right-20">
                        <div className="text-medium text-color-black">
                            <FormattedMessage id="e4c.vehicle.details.emanagercontrol.title" />
                        </div>
                        <div className="text-color-dark">
                            <FormattedMessage
                                id={
                                    unsavedHasControl
                                        ? 'e4c.vehicle.details.emanagercontrol.description.on'
                                        : 'e4c.vehicle.details.emanagercontrol.description.off'
                                }
                            />
                        </div>
                    </div>
                    <div className="text-color-dark">
                        <Switch
                            checked={unsavedHasControl}
                            disabled={isReadOnly}
                            onChange={value => changeEmanagerControl(value)}
                        />
                    </div>
                </div>
            )}
            <div className="margin-bottom-20">
                <span className="padding-right-5 text-bold">
                    <FormattedMessage id="e4c.vehicle.details.chargingMode.title" />
                </span>
                <TooltipRef
                    tooltipContent={intl.formatMessage({ id: 'e4c.vehicle.details.chargingMode.tooltip' })}
                    placement={PLACEMENT.BOTTOM_START}
                    width={400}
                    textAlignment="left"
                    ellipsedChildren={false}
                >
                    <span className="rioglyph rioglyph-exclamation-sign" />
                </TooltipRef>
            </div>
            <div>
                <ul className="nav nav-tabs nav-justified nav-justified-word-wrap">
                    {chargingTabs.map(tab => (
                        <li
                            key={tab.id}
                            className={classNames({
                                active: activeTab === tab.id,
                                disabled: (isReadOnly || !unsavedHasControl) && activeTab !== tab.id,
                            })}
                        >
                            <a onClick={() => handleSwitchTabs(tab.id)} data-testid={tab.id}>
                                {tab.label}
                            </a>
                        </li>
                    ))}
                </ul>
                {chargingTabs.map(
                    tab =>
                        activeTab === tab.id && (
                            <div key={tab.id} id={tab.id} className="tab-content-bordered padding-25">
                                {tab.content}
                            </div>
                        )
                )}
            </div>
        </div>
    );
};

export default ChargingMode;
