import cn from 'classnames';
import { OverviewTeaserProperties } from '../types';
import { FormattedMessage } from 'react-intl';
import UpsellButton from '../../common/components/upsell/UpSellButton';
import { SkeletonOverviewContentTeaser } from '../skeleton/SkeletonOverviewTeaser';
import { ElectricVehicleIcon } from '../../common/components/ElectricVehicleIcon/ElectricVehicleIcon';
import VehicleType from '../../common/vehicles/VehicleType';

interface VehiclesTextProps {
    count: number;
}

const VehiclesText = (props: VehiclesTextProps): JSX.Element => {
    if (props.count === 1) {
        return <FormattedMessage id={'e4c.vehicles.vehicle'} />;
    } else {
        return <FormattedMessage id={'e4c.overview.vehicles'} />;
    }
};

const ProductBookingSummary = ({
    count,
    productName,
    color,
}: {
    count: number;
    productName: string;
    color?: 'primary';
}) => {
    return (
        <div className="margin-bottom-10 text-light display-flex align-items-center">
            <span
                className={cn(
                    ' text-size-10 label label-condensed width-30',
                    color === 'primary' ? 'label-primary' : 'label-default'
                )}
            >
                {productName.substr(0, 1)}
            </span>
            <span className="padding-left-5 padding-right-5 font-size-20 text-medium">{count}</span>
            <span>
                <VehiclesText count={count} />
            </span>
        </div>
    );
};

const OverviewContentTeaser = (props: OverviewTeaserProperties): JSX.Element => {
    return props.fetchingElectricVehicles ? (
        <SkeletonOverviewContentTeaser />
    ) : (
        <div className="teaser-content">
            <p className="teaser-content-subtitle display-flex">
                <FormattedMessage id={'e4c.overview.currentConditionOfFleet'} />
                ,&nbsp;
                <span style={{ textTransform: 'lowercase' }}>
                    <FormattedMessage id={'e4c.overview.including'} />
                </span>{' '}
                <ElectricVehicleIcon
                    vehicleType={VehicleType.BUS}
                    size="text-size-20"
                    extraClasses="padding-left-4 padding-right-4"
                />
                <span className="text-medium">{props.vehiclesCount}</span> <VehiclesText count={props.vehiclesCount} />:
            </p>
            <section className="font-size-15">
                <ProductBookingSummary productName="S" count={props.sVehicleCount} />
                <ProductBookingSummary productName="M" color="primary" count={props.mVehicleCount} />
            </section>
            <div className="width-200">
                <UpsellButton />
            </div>
        </div>
    );
};

export default OverviewContentTeaser;
