import { ReactElement } from 'react';
import { MultiselectOptionsType, VehiclesSelectionDropDownPros } from '../../types';
import { ElectricVehicleIcon } from '../../../common/components/ElectricVehicleIcon/ElectricVehicleIcon';
import VehicleType from '../../../common/vehicles/VehicleType';

const VehiclesSelectionDropDown = (props: VehiclesSelectionDropDownPros): ReactElement => {
    const { currentEndDate, currentStartDate, setShowSelectionDropdown, selectedVehicleIds, vehiclesCount } = props;

    return (
        <div className="margin-top-10 float-right display-flex">
            <ElectricVehicleIcon vehicleType={VehicleType.BUS} size="text-size-20" extraClasses="padding-right-5" />
            <span className="number-vehicles-selected text-size-12 padding-right-20 number-vehicles-selected">
                {selectedVehicleIds.includes(MultiselectOptionsType.ALL_OPTIONS)
                    ? vehiclesCount
                    : selectedVehicleIds.length}
            </span>
            <span className="text-size-14">{`${currentStartDate} → ${currentEndDate}`}</span>
            <span
                className="selection-dropdown-button rioglyph rioglyph rioglyph-option-vertical padding-left-10 text-size-18 cursor-pointer"
                onClick={(): void => setShowSelectionDropdown(true)}
            />
        </div>
    );
};

export default VehiclesSelectionDropDown;
