import moment from 'moment-timezone';
import { currentTimezone } from '../../../../utils/timezone';

export const convertDepartureTime = (departureTime: string, zoneId: string): string => {
    const [hours, minutes] = departureTime.split(':').map(Number);
    const formattedDepartureTime = moment.tz({ hours, minutes }, zoneId);
    return formattedDepartureTime.tz(currentTimezone).format('HH:mm');
};

export const convertDateToDayMonthYear = (date: Date, locale: string): string => {
    return date.toLocaleDateString(locale, { day: '2-digit', month: '2-digit', year: 'numeric' });
};
