import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { VehiclesProperties } from '../types';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import ApplicationLayoutBodyBanner from '@rio-cloud/rio-uikit/ApplicationLayoutBodyBanner';
import ConfirmationDialog from '@rio-cloud/rio-uikit/ConfirmationDialog';
import { FormattedMessage } from 'react-intl';
import VehiclesBody from './table/VehiclesBody';
import { useHistory } from 'react-router';
import { ErrorCodeEnum } from '../../common/enums/ErrorCode';
import { config } from '../../../../config';
import ErrorState from '../../common/components/states/ErrorState';
import { AssetTreeContext } from '../../../../providers/contexts';
import { useVehicleChargingAPI } from '../queries/useVehicleChargingAPI';
import NewVehicleDetailsSidebar from './details/vehicleDetails/NewVehicleDetailsSidebar';

type VehiclesProps = VehiclesProperties;

const Vehicles = ({ selectRow, selectedRow, vehiclesLoadingErrorCode, accessToken }: VehiclesProps): ReactElement => {
    const [previousSelectedRow, setPreviousSelectedRow] = useState(selectedRow);
    const [unsavedVehicleChanges, setUnsavedVehicleChanges] = useState<boolean>(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
    const history = useHistory();
    const { selectedAssetIds: selectedVehicleIds } = useContext(AssetTreeContext);
    const { isPending: isLoading, isError, vehicleChargingData } = useVehicleChargingAPI(accessToken);

    useEffect(() => {
        const assetId = getVehicleIdFromUrl();
        if (assetId) {
            selectRow(assetId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateUrl = useCallback(
        (pathParamValue: string): void => {
            const pathParam = pathParamValue ? `/${pathParamValue}` : '';
            history.push({ pathname: `/vehicles${pathParam}`, search: history.location.search });
        },
        [history]
    );

    const onSidebarChange = useCallback((): void => {
        setUnsavedVehicleChanges(false);
        setShowConfirmDialog(false);
        selectRow('');
        updateUrl('');
        setPreviousSelectedRow('');
    }, [selectRow, updateUrl]);

    useEffect(() => {
        if (selectedRow === previousSelectedRow) {
            return;
        }

        // Show confirmation dialog if there are unsaved changes
        if (unsavedVehicleChanges) {
            setShowConfirmDialog(true);
            return;
        }

        // Closes the sidebar if user selects the same vehicle
        if (!selectedRow) {
            onSidebarChange();
        } else {
            // Opens vehicle details for the new selected vehicle
            setPreviousSelectedRow(selectedRow);
            updateUrl(selectedRow);
        }
    }, [selectedRow, previousSelectedRow, unsavedVehicleChanges, onSidebarChange, updateUrl]);

    const getVehicleIdFromUrl = (): string | null => {
        const params = history.location.pathname.split('/').filter(param => param !== 'vehicles' && param !== '');
        return params.length > 0 ? params[0] : null;
    };

    const vehicleSelected = vehicleChargingData?.find(a => a.assetId === previousSelectedRow);

    const handleDiscardChanges = (): void => {
        if (selectedRow && selectedRow !== previousSelectedRow) {
            setPreviousSelectedRow(selectedRow);
            updateUrl(selectedRow);
        } else {
            selectRow('');
            updateUrl('');
            setPreviousSelectedRow('');
        }
        setUnsavedVehicleChanges(false);
        setShowConfirmDialog(false);
    };

    const handleKeepEditing = () => {
        setShowConfirmDialog(false);
        if (selectedRow !== previousSelectedRow) {
            selectRow(getVehicleIdFromUrl());
        }
    };

    const applicationBodyBanner = config.banner.enable && (
        <ApplicationLayoutBodyBanner>{config.banner.message}</ApplicationLayoutBodyBanner>
    );

    return (
        <>
            {vehiclesLoadingErrorCode || isError ? (
                <ApplicationLayout.Body className={'responsive'} banner={applicationBodyBanner}>
                    <ErrorState
                        stateProps={{
                            type: vehiclesLoadingErrorCode === ErrorCodeEnum.FORBIDDEN ? 'forbidden' : 'general',
                        }}
                    />
                </ApplicationLayout.Body>
            ) : (
                <>
                    <ConfirmationDialog
                        show={showConfirmDialog}
                        title={<FormattedMessage id={'e4c.vehicle.details.unsavedChangesConfirmationDialog.title'} />}
                        content={<FormattedMessage id="e4c.vehicles.details.unsavedChangesText" />}
                        onClickConfirm={handleDiscardChanges}
                        onClickCancel={handleKeepEditing}
                        cancelButtonText={<FormattedMessage id="e4c.global.keepEditing" />}
                        confirmButtonText={<FormattedMessage id="e4c.global.discardChanges" />}
                        disableConfirm={false}
                        useOverflow={false}
                        bsSize={'sm'}
                    />
                    <ApplicationLayout.Sidebar className="right">
                        {previousSelectedRow && vehicleSelected && (
                            <NewVehicleDetailsSidebar
                                vehicle={vehicleSelected}
                                onCloseSidebar={onSidebarChange}
                                onToggleConfirmDialog={setShowConfirmDialog}
                                onToggleUnsavedVehicleChanges={setUnsavedVehicleChanges}
                            />
                        )}
                    </ApplicationLayout.Sidebar>
                    <ApplicationLayout.Body className={'responsive'} banner={applicationBodyBanner}>
                        <VehiclesBody
                            selectedVehicleIds={selectedVehicleIds}
                            vehicleChargingData={vehicleChargingData}
                            isLoading={isLoading}
                        />
                    </ApplicationLayout.Body>
                </>
            )}
        </>
    );
};

export default Vehicles;
